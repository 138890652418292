import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1b490688"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "speech-to-text"
};
const _hoisted_2 = {
  class: "flex-box"
};
const _hoisted_3 = {
  class: "recording-indicator"
};
const _hoisted_4 = {
  ref: "waveform",
  class: "waveform"
};
const _hoisted_5 = {
  style: {
    "padding": "40px 0"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_button = _resolveComponent("van-button");
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_empty = _resolveComponent("van-empty");
  const _component_van_loading = _resolveComponent("van-loading");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default", {}, undefined, true), _createVNode(_component_van_button, {
    style: {
      "flex": "1",
      "margin": "0 10px"
    },
    type: "success",
    onTouchstart: _withModifiers($options.handleTouchStart, ["prevent"]),
    onTouchend: _withModifiers($options.handleTouchEnd, ["prevent"]),
    onTouchmove: $options.handleTouchMove,
    disabled: $data.isResolving
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($options.btnText), 1)]),
    _: 1
  }, 8, ["onTouchstart", "onTouchend", "onTouchmove", "disabled"]), _createVNode(_component_van_button, {
    type: "primary",
    style: {
      "padding": "0 16px",
      "margin-right": "5px"
    },
    onClick: _cache[0] || (_cache[0] = $event => $options.emitSpeechText()),
    disabled: $data.isResolving || $data.speechResolvedText.length === 0 || $data.isRecording
  }, {
    default: _withCtx(() => [_createTextVNode("发送")]),
    _: 1
  }, 8, ["disabled"])]), _createElementVNode("div", _hoisted_3, [_withDirectives(_createElementVNode("canvas", _hoisted_4, null, 512), [[_vShow, $data.isRecording && !$data.isResolving]]), _withDirectives(_createElementVNode("p", {
    style: {
      "min-height": "60px"
    }
  }, _toDisplayString($data.speechResolvedText), 513), [[_vShow, !$data.isRecording && !$data.isResolving && $data.speechResolvedText.length > 0]]), _withDirectives(_createVNode(_component_van_empty, {
    "image-size": "30",
    description: "没有听到您的声音哦"
  }, {
    image: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "bulb-o"
    })]),
    _: 1
  }, 512), [[_vShow, $data.audioChunks.length && !$data.isResolving && !$data.isRecording && $data.speechResolvedText.length === 0]]), _withDirectives(_createElementVNode("div", _hoisted_5, [_createVNode(_component_van_loading, {
    size: "24px"
  }, {
    default: _withCtx(() => [_createTextVNode("正在解析...")]),
    _: 1
  })], 512), [[_vShow, $data.audioChunks.length > 0 && $data.isResolving]])])]);
}