import speechCompVue from "./speechComp.vue";
export default {
  props: ["gptActions", "chatGptModelId", "showChoosed"],
  emits: ["getInputValue", "getChangedModel"],
  // 声明自定义事件
  components: {
    speechCompVue
  },
  data() {
    return {
      showOverlay: false,
      gptModelsVisible: false,
      message: "",
      recording: true
    };
  },
  methods: {
    getModelName() {
      let item = {
        name: ""
      };
      if (this.gptActions) {
        if (this.chatGptModelId) {
          item = this.gptActions.find(i => i.gpt_model_id === this.chatGptModelId);
        } else {
          item = this.gptActions.find(i => i.gpt_model_id === this.$store.state.gpt_model_id);
        }
      }
      return item.name;
    },
    toggleOverlay(boll) {
      this.showOverlay = boll;
    },
    onModelSelect(action) {
      this.$emit("getChangedModel", action.gpt_model_id);
    },
    onSubmit() {
      this.$emit("getInputValue", this.message);
    },
    clearInput() {
      this.message = "";
    },
    getSpeechText(value) {
      this.$emit("getInputValue", value);
    }
  }
};