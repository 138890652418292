import { marked } from "marked";
import hljs from "highlight.js";
import "highlight.js/styles/default.css"; // 引入 highlight.js 的样式文件

export default {
  name: "MarkdownRenderer",
  props: {
    markdownContent: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      renderedMarkdown: ""
    };
  },
  watch: {
    markdownContent: {
      immediate: true,
      handler(newValue) {
        this.renderedMarkdown = this.renderMarkdown(newValue);
      }
    }
  },
  methods: {
    renderMarkdown(content) {
      if (!content) return "";
      // 设置 marked 的渲染器以使用 highlight.js 进行代码高亮
      marked.setOptions({
        renderer: new marked.Renderer(),
        highlight: function (code, lang) {
          const language = hljs.getLanguage(lang) ? lang : "plaintext";
          return hljs.highlight(code, {
            language
          }).value;
        },
        langPrefix: "hljs language-",
        // 用于代码块的类前缀
        pedantic: false,
        gfm: true,
        breaks: false,
        sanitize: false,
        smartLists: true,
        smartypants: false,
        xhtml: false
      });
      return marked(content);
    }
  }
};