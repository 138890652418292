import "core-js/modules/es.array.push.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.to-reversed.js";
import "core-js/modules/es.typed-array.to-sorted.js";
import "core-js/modules/es.typed-array.with.js";
export default {
  data() {
    return {
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      isCancelled: false,
      recordingTime: 0,
      recordingInterval: null,
      audioContext: null,
      analyser: null,
      canvas: null,
      canvasCtx: null,
      speechResolvedText: "",
      isResolving: false,
      stream: null,
      hasMicrophonePermission: false // 新增属性来跟踪麦克风权限状态
    };
  },
  computed: {
    btnText() {
      let str = this.isRecording ? "正在录音，松手识别语音" : "按住说话即可录音";
      return str;
    }
  },
  methods: {
    emitSpeechText() {
      if (this.speechResolvedText) {
        this.$emit("getSpeechText", this.speechResolvedText);
      }
    },
    async handleTouchStart() {
      if (!this.hasMicrophonePermission) {
        try {
          this.stream = await navigator.mediaDevices.getUserMedia({
            audio: true
          });
          this.hasMicrophonePermission = true; // 用户已授权
        } catch (error) {
          this.$notify({
            message: "麦克风权限没打开将不能录音哦～",
            type: "danger"
          });
        }
      } else {
        this.startRecording(); // 用户已授权，正常开始录音
      }
    },
    startRecording() {
      if (!this.hasMicrophonePermission) {
        return;
      }
      this.$emit("toggleOverlay", true);
      this.mediaRecorder = new MediaRecorder(this.stream);
      this.audioChunks = [];
      this.speechResolvedText = "";
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.analyser = this.audioContext.createAnalyser();
      const source = this.audioContext.createMediaStreamSource(this.stream);
      source.connect(this.analyser);
      this.canvas = this.$refs.waveform;
      this.canvasCtx = this.canvas.getContext("2d");
      this.mediaRecorder.ondataavailable = event => {
        this.audioChunks.push(event.data);
      };
      this.mediaRecorder.onstop = async () => {
        clearInterval(this.recordingInterval);
        if (this.isCancelled) {
          this.isCancelled = false;
          return;
        }
        const audioBlob = new Blob(this.audioChunks, {
          type: "audio/mp3"
        });
        console.log("audioBlob", audioBlob.type);
        const audioFile = new File([audioBlob], "audio.mp3", {
          type: "audio/mp3",
          lastModified: new Date().getTime() // 使用当前时间作为文件的最后修改时间
        });
        console.log("audioFile", audioFile.type);
        const formData = new FormData();
        formData.append("file", audioFile);
        formData.append("second", this.recordingTime);
        this.isResolving = true;
        this.recordingTime = 0;
        this.$axios.speechToText(formData).then(res => {
          this.isResolving = false;
          if (res.code === 1) {
            this.speechResolvedText = res.data.text;
            console.log(this.speechResolvedText);
          } else {
            this.speechResolvedText = "";
          }
        }).catch(() => {
          this.isResolving = false;
        });
      };
      this.mediaRecorder.start();
      this.isRecording = true;
      this.startWaveform();
      this.startTimer();
    },
    releaseMediaStream() {
      this.hasMicrophonePermission = false;
      if (this.stream) {
        const tracks = this.stream.getTracks();
        tracks.forEach(track => track.stop());
        this.stream = null;
      }
    },
    stopRecording() {
      if (this.mediaRecorder && this.isRecording) {
        this.mediaRecorder.stop();
        this.isRecording = false;
      }
    },
    closeOverlay() {
      this.$emit("toggleOverlay", false);
      this.stopRecording();
      this.speechResolvedText = "";
      this.isResolving = false;
      this.audioChunks = [];
    },
    handleTouchMove(event) {
      // 获取触摸点相对于视口的位置
      const touchY = event.touches[0].clientY;
      // 计算视口的高度
      const viewportHeight = window.innerHeight;
      // 计算触摸点与视口底部的距离
      const distanceFromBottom = viewportHeight - touchY;
      // 如果距离超过150px，则取消录音并停止
      if (distanceFromBottom > 150) {
        this.isCancelled = true;
        this.$toast({
          type: "fail",
          message: "已取消录音",
          position: "bottom"
        });
        this.stopRecording();
      }
    },
    handleTouchEnd() {
      this.stopRecording();
    },
    startTimer() {
      this.recordingTime = 0;
      this.recordingInterval = setInterval(() => {
        this.recordingTime++;
      }, 1000);
    },
    startWaveform() {
      // Configure the analyser
      this.analyser.fftSize = 2048;
      const bufferLength = this.analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      const draw = () => {
        if (!this.isRecording) {
          return;
        }
        requestAnimationFrame(draw);

        // Get the frequency data from the analyser
        this.analyser.getByteFrequencyData(dataArray);

        // Clear the canvas
        this.canvasCtx.fillStyle = "rgb(255, 255, 255)"; // Light green background
        this.canvasCtx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        const waveformWidth = this.canvas.width * 0.2; // 40% of the canvas width
        const maxBarHeight = 50; // Maximum height of the bars
        const minBarHeight = 5; // Minimum height of the bars to show a slight bounce when silent
        const barWidth = waveformWidth / 10; // Fixed width for each of the 10 bars
        const scaleFactor = (maxBarHeight - minBarHeight) / 128; // Scale factor for the height of the bars
        const offsetX = (this.canvas.width - waveformWidth) / 2; // Offset to center the waveform horizontally

        // Set the style for the bars
        this.canvasCtx.fillStyle = "#dcdee0";

        // Draw the bars
        for (let i = 0; i < 10; i++) {
          // Calculate the index to use from the frequency data, spread evenly across the dataArray
          const dataValueIndex = Math.floor(i * bufferLength / 10);
          const value = dataArray[dataValueIndex];
          let barHeight = value * scaleFactor + minBarHeight;
          barHeight = barHeight > maxBarHeight ? maxBarHeight : barHeight; // Ensure the bar doesn't exceed the max height

          // Center the bar within the maximum bar height
          const y = this.canvas.height / 2 - barHeight / 2;
          this.canvasCtx.fillRect(offsetX + i * barWidth, y, barWidth - 3, barHeight); // -1 for the gap between bars
        }

        // Draw the recording time text
        this.canvasCtx.fillStyle = "#00c853"; // Black color for the text
        this.canvasCtx.font = "16px";
        this.canvasCtx.textAlign = "center";
        const text = `${this.recordingTime}s`;
        this.canvasCtx.fillText(text, this.canvas.width / 2, this.canvas.height * 0.8); // Position the text at the bottom of the canvas
      };
      draw();
    }
  },
  beforeUnmount() {
    this.releaseMediaStream();
  }
};